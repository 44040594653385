<template>
  <b-card
    tag="article"
    no-body
    style="max-width: 640px"
    class="custom-card supplier-card"
  >
    <b-card-header
      header-tag="section"
      class="custom-card-header supplier-card-header d-flex align-items-center"
    >
      <h3>Datos del proveedor</h3>
    </b-card-header>
    <b-card-body class="custom-card-body supplier-card-body">
      <div class="alert-container">
        <SimpleAlert
          type="light-gray"
          text="Para continuar con el uso de la plataforma se requiere lo siguientes datos"
        >
          <template slot="icon">
            <span class="modal-icon mr-2">
              <i class="ri-error-warning-line"></i>
            </span>
          </template>
        </SimpleAlert>
      </div>
      <form class="custom-form supplier-form" @submit.prevent="save">
        <div class="form-group" v-if="!existingCompanyAddress">
          <label for="companyAddress">Domicilio fiscal *</label>
          <input
              id="companyAddress"
              type="text"
              v-model.trim="form.companyAddress"
              autocomplete="off"
              placeholder="Ingresar domicilio fiscal"
              class="custom-form-control"
              :class="{ 'custom-form-control-error': $v.form.companyAddress.$error }"
          />
          <form-error
              message="Domicilio fiscal es requerido"
              v-if="$v.form.companyAddress.$error && !$v.form.companyAddress.required"
          ></form-error>
        </div>
        <div class="form-group" v-if="!existingMobile">
          <label for="mobile">Celular *</label>
          <input
            id="mobile"
            type="text"
            maxlength="9"
            v-model.trim="form.mobile"
            autocomplete="off"
            placeholder="Ingresar celular"
            class="custom-form-control"
            :class="{
              'custom-form-control-error': $v.form.mobile.$error,
            }"
          />
          <form-error
            message="Celular es requerido"
            v-if="$v.form.mobile.$error && !$v.form.mobile.required"
          ></form-error>
          <form-error
            message="Ingresa un celular válido de 9 dígitos"
            v-if="$v.form.mobile.$error && !$v.form.mobile.mobile"
          ></form-error>
        </div>

        <div class="form-group" v-if="!existingEmail">
          <label for="email">Correo electrónico *</label>
          <input
            id="email"
            type="text"
            v-model.trim="form.email"
            autocomplete="off"
            placeholder="Ingresar correo electrónico"
            class="custom-form-control"
            :class="{ 'custom-form-control-error': $v.form.email.$error }"
          />
          <form-error
            message="Correo electrónico es requerido"
            v-if="$v.form.email.$error && !$v.form.email.required"
          ></form-error>
          <form-error
            message="Ingresa un correo electrónico válido"
            v-if="$v.form.email.$error && !$v.form.email.email"
          ></form-error>
        </div>
        <div class="form-group-buttons text-right">
          <button @click="cancel" class="button button-cancel" type="button">
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Guardar</button>
        </div>
      </form>
    </b-card-body>
  </b-card>
</template>

<script>
import { SimpleAlert, FormError } from "wize-admin";
import { email, requiredIf } from "vuelidate/lib/validators";
import { CustomValidators, Alert } from "@/core/utils";
import { UserService } from "@/core/services";
import { mapGetters } from "vuex";

export default {
  name: "SupplierForm",
  components: {
    SimpleAlert,
    FormError,
  },
  data() {
    return {
      existingMobile: null,
      existingEmail: null,
      existingCompanyAddress: null,
      form: {
        mobile: null,
        email: null,
        companyAddress: null,
      },
    };
  },
  validations: {
    form: {
      mobile: { required: requiredIf(function () {
          return !this.existingMobile;
        }), mobile: CustomValidators.mobile },
      email: { required: requiredIf(function () {
          return !this.existingEmail;
        }), email },
      companyAddress: { required: requiredIf(function () {
          return !this.existingCompanyAddress;
        }) },
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const data = { ...this.form };
      try {
        const resp = await UserService.updatePusherDetails(data);
        await this.$store.commit("auth/user", resp.payload);
        await this.$router.push({ name: "profile" });
      } catch (e) {
        Alert.error(e.errors.message);
        console.error(e);
      }
    },
    cancel() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  created() {
    this.existingMobile = this.user.mobile;
    this.existingEmail = this.user.email;
    this.existingCompanyAddress = this.user.companyAddress;
  },
};
</script>

<style lang="stylus">
@import '../Styles/supplier-form';
</style>
