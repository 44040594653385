import {http} from '@/http'

export default {
  getPayments(data, params) {
    return http.post('/payments/list', data, {params})
  },
  getPayment(code) {
    return http.get(`/payments/${code}`)
  },
}
