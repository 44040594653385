<template>
  <header class="admin-header">
    <div class="container d-flex justify-content-between align-items-center py-0">
      <div>
        <router-link
            :to="{ name: 'welcome' }"
            class="d-flex align-items-center text-decoration-none"
        >
          <img
              src="@/core/assets/images/layout/logo-admin.png"
              alt="NAGA"
          />
          <span class="title text-white w-50 mt-1">NAGA</span>
        </router-link>
      </div>

      <div class="d-flex align-items-center">
        <UserDropdown/>
      </div>
    </div>
  </header>
</template>

<script>
import { UserDropdown } from 'wize-admin'

export default {
  name: "AdminHeader",
  components: {
    UserDropdown
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/header";
</style>
