var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_vm._m(0),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v("Cambiar contraseña actual")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"current"}},[_vm._v("Contraseña actual *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.currentPassword),expression:"form.currentPassword",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                  'custom-form-control-error': _vm.$v.form.currentPassword.$error,
                },attrs:{"id":"current","type":"password","autocomplete":"off","maxlength":"50","placeholder":"Ingresar su contraseña actual"},domProps:{"value":(_vm.form.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "currentPassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                  _vm.$v.form.currentPassword.$error &&
                  !_vm.$v.form.currentPassword.required
                )?_c('form-error',{attrs:{"message":"Contraseña actual es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña nueva *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.newPassword),expression:"form.newPassword",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                  'custom-form-control-error': _vm.$v.form.newPassword.$error,
                },attrs:{"id":"password","type":"password","autocomplete":"off","maxlength":"50","placeholder":"Ingresar su contraseña nueva"},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "newPassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                  _vm.$v.form.newPassword.$error && !_vm.$v.form.newPassword.required
                )?_c('form-error',{attrs:{"message":"Contraseña nueva es requerida"}}):_vm._e(),(
                  _vm.$v.form.newPassword.$error && !_vm.$v.form.newPassword.minLength
                )?_c('form-error',{attrs:{"message":"La contraseña debe tener 6 dígitos como mínimo"}}):_vm._e()],1),_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"new"}},[_vm._v("Repite contraseña nueva *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.repeatPassword),expression:"form.repeatPassword",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                  'custom-form-control-error': _vm.$v.form.repeatPassword.$error,
                },attrs:{"id":"repeat","type":"password","autocomplete":"off","maxlength":"50","placeholder":"Ingresar su contraseña nueva"},domProps:{"value":(_vm.form.repeatPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "repeatPassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                  _vm.$v.form.repeatPassword.$error &&
                  !_vm.$v.form.repeatPassword.required
                )?_c('form-error',{attrs:{"message":"Contraseña nueva es requerida"}}):_vm._e(),(
                  _vm.$v.form.repeatPassword.$error &&
                  !_vm.$v.form.repeatPassword.sameAs
                )?_c('form-error',{attrs:{"message":"Las contraseñas ingresadas no coinciden"}}):_vm._e(),(_vm.error)?_c('form-error',{attrs:{"message":_vm.error}}):_vm._e()],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Actualizar ")])])])])])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Cambiar contraseña")])])}]

export { render, staticRenderFns }