<template>
  <div class="form-reset" v-if="show">
    <section class="form-card d-flex flex-column justify-content-center position-relative" >
      <div v-if="!submitted">
        <h2>Olvidé mi contraseña</h2>
        <p class="description mb-0">
          Ingresa tu dirección de correo electrónico y te enviaremos las
          instrucciones para restablecer tu contraseña.
        </p>

        <b-form
            class="form custom-form text-left"
            @submit.prevent="submit"
        >
          <div class="form-group mb-0">
            <label for="email">Correo electrónico</label>
            <input
                id="email"
                type="text"
                v-model="form.email"
                placeholder="Ingresa tu correo electrónico"
                class="custom-form-control"
                :class="{
              'custom-form-control-error':
                $v.form.email.$error,
            }"
            />
            <form-error
                message="Correo electrónico es requerido"
                v-if="$v.form.email.$error && !$v.form.email.required"
            ></form-error>
            <form-error
                message="El correo electrónico ingresado no es válido"
                v-if="$v.form.email.$error && !$v.form.email.email"
            ></form-error>
            <form-error
                :message="error"
                v-if="error"
            ></form-error>
          </div>

          <b-button
              class="submit-button"
              type="submit"
              variant="none"
              block
          >Enviar instrucciones
          </b-button
          >
        </b-form>

        <button class="modal-close bg-transparent border-0" @click="hideModal()">
          <img
              src="@/core/assets/images/components/close-modal.svg"
              alt="Cerrar modal"
          />
        </button>
      </div>
      <div v-if="submitted">
        <h2>Te enviamos un correo electrónico</h2>
        <p>
          Busca en tu bandeja de entrada el correo electrónico
          para restablecer tu contraseña. Si no lo recibes en
          los próximos minutos comunícate con el
          administrador del sistema.
        </p>
        <b-button
            class="mt-3 submit-button"
            type="submit"
            variant="none"
            @click="hideModal()"
            block
        >Aceptar
        </b-button>
        <button class="modal-close bg-transparent border-0" @click="hideModal()">
          <img
              src="@/core/assets/images/components/close-modal.svg"
              alt="Cerrar modal"
          />
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {SecurityService} from "@/core/services";
import { FormError, ModalMixin } from "wize-admin";

export default {
  data() {
    return {
      form: {
        email: "",
      },
      submitted: false,
      error: null
    };
  },
  mixins: [ModalMixin],
  components: {
    FormError,
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.error = null;
        await this.$store.dispatch("app/loading", true);
        const data = {...this.form}
        await SecurityService.recoverPassword(data);
        this.submitted = true;
      } catch (e) {
        console.error(e);
        this.error = 'Ocurrió un error al intentar recuperar la contraseña, por favor intente nuevamente'
        this.submitted = false;
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    hideModal(){
      this.$emit('hide');
      this.submitted = false;
      this.error = null;
      this.form.email = null;
      this.$v.$reset()
    },
  },
  created() {
    this.submitted = false
  }
};
</script>

<style lang="stylus" scoped>
@import '../Styles/form-reset';
</style>
