import PaymentsView from "../PaymentsView.vue";
import PaymentsPage from "../Pages/PaymentsPage.vue";
import UploadVoucherPage from "../Pages/UploadVoucherPage.vue";
import UploadCreditNotePage from "../Pages/UploadCreditNotePage.vue";

const routes = [
  {
    path: '/pagos',
    component: PaymentsView,
    children: [
      {
        path: '',
        name: 'payments',
        component: PaymentsPage,
      },
      {
        path: ':code/carga',
        name: 'upload-voucher',
        component: UploadVoucherPage,
        props: (route) => {
          const code = route.params.code;
          return {code}
        },
      },
      {
        path: ':code/nota-credito/carga',
        name: 'upload-credit-note',
        component: UploadCreditNotePage,
        props: (route) => {
          const code = route.params.code;
          return {code}
        },
      },
      {
        path: 'carga',
        name: 'upload-voucher-empty',
        component: UploadVoucherPage
      },
    ]
  }
]

export default routes
