<template>
  <div>
    <section class="dashboard-banner">
      <h1 class="dashboard-title">Mis datos</h1>
    </section>

    <div class="card-details" v-if="user">
      <div class="section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <h3 class="label mt-0">RUC</h3>
              <p class="text">{{ user.companyId }}</p>
            </div>

            <div class="col-md-6">
              <h3 class="label mt-0">Razón social</h3>
              <p class="text">{{ user.companyName }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h3 class="label">Domicilio fiscal</h3>
              <p class="text">
                {{ user.companyAddress }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h3 class="label">Nombre de contacto</h3>
              <p class="text">
                {{ user.contactName }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Correo electrónico</h3>
              <p class="text" v-empty="user.email"></p>
            </div>

            <div class="col-md-6">
              <h3 class="label">Teléfono</h3>
              <p class="text" v-empty="user.phone"></p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Celular</h3>
              <p class="text" v-empty="user.mobile"></p>
            </div>

            <div class="col-md-6">
              <h3 class="label">Cuenta de detracciones</h3>
              <p class="text" v-empty="user.detractionAccount"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="section border-0 pb-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Banco</h3>
              <p
                class="text"
                v-empty="user.bank != null ? user.bank.name : null"
              ></p>
            </div>

            <div class="col-md-6">
              <h3 class="label">Titular de la cuenta</h3>
              <p class="text" v-empty="user.bankHolder"></p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Número de cta. bancaria</h3>
              <p class="text" v-empty="user.bankAccount"></p>
            </div>

            <div class="col-md-6">
              <h3 class="label">CCI</h3>
              <p class="text" v-empty="user.bankInterAccount"></p>
            </div>

            <div class="col-12 mt-4">
              <SimpleAlert
                type="light-gray"
                text="Si desea modificar alguno de los datos por favor envíe un correo a <a href='mailto:proveedores@grupoauren.pe'>proveedores@grupoauren.pe</a> solicitando los cambios."
              >
                <template slot="icon">
                  <span class="modal-icon mr-2">
                    <i class="ri-error-warning-line"></i>
                  </span>
                </template>
              </SimpleAlert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SimpleAlert } from "wize-admin";
import { mapGetters } from "vuex";

export default {
  name: "ProfilePage",
  components: {
    SimpleAlert,
  },
  methods: {},
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  async created() {
    await this.$store.dispatch("auth/setUser");
  },
};
</script>

<style scoped lang="stylus">
@import '../Styles/profile-page';
</style>
