import { helpers } from 'vuelidate/lib/validators'
const MEGA_TO_BYTES_FACTOR = 1048576;

const maxFileSize = (numberMegaBytes) => (value) =>  {
  if (!value) {
    return true;
  }
  return (value.size < MEGA_TO_BYTES_FACTOR * numberMegaBytes);
};

const fileType = (typeFile) => (value) =>  {
  if (!value) {
    return true;
  }
  return (value.type === typeFile);
};

const greaterThan = (param) =>
  helpers.withParams(
    {type: 'greaterThan', value: param},
    (value) => !helpers.req(value) || value > param
  )

const mobile = (value,) => {
  if (!value) return true;
  const regx = RegExp('^[0-9]*$');
  return regx.test(value) && value.length === 9;
};

const url = (url) => /(https?:\/\/)(www\.)?(?!www)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&/=]*)/.test(url);

const voucherCodeFormat = function (value) {
  if (!value) return true;
  const parts = value.split("-");
  return parts.length === 2 && parts[0].length > 0 && parts[1].length > 0;
}

export default {
  maxFileSize,
  fileType,
  url,
  greaterThan,
  mobile,
  voucherCodeFormat
}

